const roundIfGreaterThanZero = (number) => {
  const decimalPart = number - Math.floor(number);
  if (decimalPart > 0) {
    return Math.round(number * 100) / 100;
  }
  return Math.floor(number);
};

module.exports = {
  roundIfGreaterThanZero
};
