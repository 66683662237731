import React, { useEffect, useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import moment from 'moment';
import { getAllStatus } from './utils/firebase';
import VinsytLogo from './assets/images/vinsyt-logo.png';
import VinsytBanner from './assets/images/banner.png';
import StatusCard from './elements/statusCard';
import { ownerWebList, thirdPartyList } from './utils/global';
import useWindowDimensions from './helper/useWindowDimensions';
import { roundIfGreaterThanZero } from './helper/global';

const Icon = ({ id, open }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    className={`${id === open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
  </svg>
);

const App = () => {
  const [open, setOpen] = React.useState(1);
  const [openThird, setOpenThird] = React.useState(1);
  const [loader, setLoader] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const { width } = useWindowDimensions();
  const [days, setDays] = useState(90);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const handleOpenThird = (value) => setOpenThird(openThird === value ? 0 : value);

  useEffect(() => {
    if (width < 800) {
      setDays(29);
    } else {
      setDays(89);
    }
  }, [width]);

  useEffect(() => {
    setLoader(true);
    getAllStatus().then((response) => {
      if (response) {
        setLoader(false);
        setStatusData(response);
      }
    });
  }, []);

  const filterData = (data, module, app) => {
    const ninetyDaysAgo = moment().subtract(days, 'days');
    const currentDate = moment();

    const countsByDate = new Map();

    const dateToCreate = moment(ninetyDaysAgo);
    while (dateToCreate.isSameOrBefore(currentDate)) {
      const formattedDate = dateToCreate.format('YYYY-MM-DD');
      countsByDate.set(formattedDate, {
        count: 0,
        app,
        module,
        status: 'success',
        reason: '',
        created_on: formattedDate
      });
      dateToCreate.add(1, 'day');
    }

    data.forEach((item) => {
      const itemDate = moment.unix(item.created_on.seconds);
      const dateKey = itemDate.format('YYYY-MM-DD');

      if (
        (module === '' || item.module === module) &&
        item.app === app &&
        itemDate.isBetween(ninetyDaysAgo, currentDate, null, '[]')
      ) {
        countsByDate.set(dateKey, { ...item, count: countsByDate.get(dateKey).count + 1 });
      }
    });

    const resultArray = [];

    countsByDate.forEach((res, dateKey) => {
      resultArray.push({
        created_on: dateKey,
        app,
        module: module === '' ? null : module,
        status: res.status,
        reason: res.reason,
        count: res.count
      });
    });

    return resultArray;
  };

  const calculateSuccessPercentage = (data) => {
    const currentDate = moment();
    const startDate = moment(currentDate).subtract(days, 'days');

    let totalSuccessCount = 0;
    let totalFailCount = 0;

    data.forEach((entry) => {
      const entryDate = moment(entry.created_on._seconds);
      if (entryDate.isSameOrAfter(startDate) && entryDate.isSameOrBefore(currentDate)) {
        if (entry.status === 'success') {
          totalSuccessCount += 1;
        } else if (entry.status === 'failed') {
          totalFailCount += 1;
        }
      }
    });

    const total = totalSuccessCount + totalFailCount;

    if (total === 0) {
      return 0;
    }

    const successPercentage = (totalSuccessCount / total) * 100;
    return roundIfGreaterThanZero(successPercentage);
  };

  return (
    <div className="App">
      <div className="max-w-screen-2md mx-auto">
        <div className="w-full aspect-[7/2] bg-[#ffffff] overflow-hidden relative">
          <img
            className="w-full h-full object-center object-cover relative"
            src={VinsytBanner}
            alt="banner img"
          />
          <div className="absolute flex items-end justify-end w-full h-full bg-[#5046e3]/20 bottom-0 p-4 backdrop-blur">
            <img className="h-8 w-suto" src={VinsytLogo} alt="vinsyt logo" />
          </div>
        </div>
        <div className="w-full p-4 text-lg font-bold text-white bg-[#5046e3] rounded-b mb-10">
          All Systems Operational
        </div>
        {loader ? (
          <div className="flex justify-center">
            <span className="loader" />
          </div>
        ) : (
          statusData && (
            <>
              <div className="w-full border-[1px] border-[#E0E0E0] rounded py-5 px-4">
                <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                  <AccordionHeader className="border-none py-0" onClick={() => handleOpen(1)}>
                    <h1>Owner Web App</h1>
                  </AccordionHeader>
                  {open !== 1 && (
                    <StatusCard
                      days={days}
                      data={filterData(statusData, '', 'owner-web-app')}
                      successRatio={calculateSuccessPercentage(
                        filterData(statusData, '', 'owner-web-app')
                      )}
                    />
                  )}
                  <AccordionBody>
                    {ownerWebList.map((item, index) => (
                      <StatusCard
                        days={days}
                        key={index}
                        title={item.module}
                        type="Operational"
                        data={filterData(statusData, item.module, item.app)}
                        successRatio={calculateSuccessPercentage(
                          filterData(statusData, item.module, item.app)
                        )}
                      />
                    ))}
                  </AccordionBody>
                </Accordion>
              </div>
              <div className="w-full border-[1px] border-[#E0E0E0] rounded py-5 px-4 mt-4 mb-5">
                <Accordion>
                  <AccordionHeader className="border-none py-0">
                    <h1>SA Mobile App</h1>
                  </AccordionHeader>
                  <StatusCard
                    days={days}
                    type="Operational"
                    data={filterData(statusData, 'status', 'sa-mobile-app')}
                    successRatio={calculateSuccessPercentage(
                      filterData(statusData, 'status', 'sa-mobile-app')
                    )}
                  />
                </Accordion>
              </div>
              <div className="w-full border-[1px] border-[#E0E0E0] rounded py-5 px-4 mt-4 mb-5">
                <Accordion open={openThird === 1} icon={<Icon id={1} open={openThird} />}>
                  <AccordionHeader className="border-none py-0" onClick={() => handleOpenThird(1)}>
                    <h1>Vinsyt Third Party</h1>
                  </AccordionHeader>
                  {openThird !== 1 && (
                    <StatusCard
                      days={days}
                      data={filterData(statusData, '', 'third-party')}
                      successRatio={calculateSuccessPercentage(
                        filterData(statusData, '', 'third-party')
                      )}
                    />
                  )}
                  <AccordionBody>
                    {thirdPartyList.map((item, index) => (
                      <StatusCard
                        days={days}
                        key={index}
                        title={item.module}
                        type="Operational"
                        data={filterData(statusData, item.module, item.app)}
                        successRatio={calculateSuccessPercentage(
                          filterData(statusData, item.module, item.app)
                        )}
                      />
                    ))}
                  </AccordionBody>
                </Accordion>
              </div>
              <div className="w-full py-5 px-4 mt-4 mb-5">
                <div className="flex flex-row items-center justify-center gap-4">
                  <div className="flex flex-row items-center gap-1">
                    <span className="w-3.5 h-3.5 bg-[#09C639] rounded-[3px]" />
                    <span className="text-[12px] text-[#928e97]">100% uptime</span>
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <span className="w-3.5 h-3.5 bg-[#fff600] rounded-[3px]" />
                    <span className="text-[12px] text-[#928e97]">80% uptime</span>
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <span className="w-3.5 h-3.5 bg-[#ffc302] rounded-[3px]" />
                    <span className="text-[12px] text-[#928e97]">50% uptime</span>
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <span className="w-3.5 h-3.5 bg-[#ff8f00] rounded-[3px]" />
                    <span className="text-[12px] text-[#928e97]">30% uptime</span>
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <span className="w-3.5 h-3.5 bg-[#ff0505] rounded-[3px]" />
                    <span className="text-[12px] text-[#928e97]">Downtime for the day</span>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default App;
