import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getConfig } from '../config';
import { FIREBASE_DOCS } from '../constants';

let app = null;
let db = null;
let functions = null;

export const initFirebase = () => {
  const config = getConfig();
  app = initializeApp(config.firebaseConfig);
  db = getFirestore(app);
  functions = getFunctions(app);
  // When want to connect with local function.
  // connectFunctionsEmulator(functions, 'localhost', 5001);
};

export const callFBFunction = async (functionName, data) => {
  const functionInstance = httpsCallable(functions, functionName);
  return functionInstance(data);
};

export const getAllStatus = async () => {
  const response = [];
  const querySnapshot = await getDocs(collection(db, FIREBASE_DOCS.APP_STATUS));
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }

  console.log(response);
  return response;
};
