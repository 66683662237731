export const ownerWebList = [
  {
    app: 'owner-web-app',
    module: 'owner-registration'
  },
  {
    app: 'owner-web-app',
    module: 'profile'
  },
  {
    app: 'owner-web-app',
    module: 'referrals'
  },
  {
    app: 'owner-web-app',
    module: 'reviews'
  },
  {
    app: 'owner-web-app',
    module: 'status'
  },
  {
    app: 'owner-web-app',
    module: 'vehicle-media'
  }
];

export const thirdPartyList = [
  {
    app: 'third-party',
    module: 'passkit'
  },
  {
    app: 'third-party',
    module: 'marketcheck'
  },
  {
    app: 'third-party',
    module: 'dignify'
  },
  {
    app: 'third-party',
    module: 'dealervault'
  },
  {
    app: 'third-party',
    module: 'carxe'
  },
  {
    app: 'third-party',
    module: 'stripo'
  },
  {
    app: 'third-party',
    module: 'sendgrid'
  },
  {
    app: 'third-party',
    module: 'twilio'
  },
  {
    app: 'third-party',
    module: 'facebook'
  }
];
