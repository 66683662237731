import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import moment from 'moment';

const StatusCard = ({ full = true, title, type, data, days, successRatio }) => (
  <div className={`w-full py-2 ${full ? '' : 'ps-5'}`}>
    <div className="w-full flex flex-row justify-between items-center mb-1">
      <div className="flex flex-row gap-2 items-center">
        <p className="text-base font-semibold text-[#63676D]">
          {title &&
            title
              .split('-')
              .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
              .join(' ')}
        </p>
      </div>
      {type && <p className="text-sm text-[#09C639]">{type}</p>}
    </div>
    <div className="w-full flex flex-row gap-1">
      {data &&
        data.map((item, index) => (
          <Tooltip
            key={index}
            className="bg-black text-white p-2 rounded-md z-50"
            placement="top"
            content={
              <div className="w-auto py-1">
                <h1 className="font-medium">
                  {item && moment(item.created_on).format('DD MMMM YYYY')}
                </h1>
                <p className="font-normal opacity-80">
                  {item && item.status === 'success' ? (
                    <span>No downtime recorded on this day.</span>
                  ) : (
                    <span className="flex gap-2 items-center mt-2">
                      {item && item.count > 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          height="20"
                          viewBox="0 0 32 32"
                          width="20"
                          xmlSpace="preserve">
                          <g>
                            <g id="Error_1_">
                              <g id="Error">
                                <circle
                                  cx="16"
                                  cy="16"
                                  id="BG"
                                  r="16"
                                  style={{ fill: '#D72828' }}
                                />
                                <path
                                  d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                                  id="Exclamatory_x5F_Sign"
                                  style={{ fill: '#E6E6E6' }}
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      )}
                      Service was down for {item.count} hours
                    </span>
                  )}
                </p>
              </div>
            }
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0, y: 25 }
            }}>
            <div
              className={`h-9 ${
                item && item.status === 'failed'
                  ? item.count <= 6
                    ? 'bg-[#fff600]'
                    : item.count <= 12
                    ? 'bg-[#ffc302]'
                    : item.count <= 18
                    ? 'bg-[#ff8f00]'
                    : item.count <= 24
                    ? 'bg-[#ff0505]'
                    : 'bg[#ff0505]'
                  : 'bg-[#09C639]'
              } flex-1 hover:bg-[#616C82]`}
              key={index}
            />
          </Tooltip>
        ))}
    </div>
    <div className="w-full relative">
      <div className="w-full border-b border-[#E0E0E0] absolute top-[50%] z-5" />
      <div className="w-full flex flex-row justify-between items-center mt-1.5 relative z-10">
        <p className="text-sm text-[#928e97] bg-white pe-3">{days + 1} days ago</p>
        <p className="text-sm text-[#928e97] bg-white px-3">{successRatio}% uptime</p>
        <p className="text-sm text-[#928e97] bg-white ps-3">Today</p>
      </div>
    </div>
  </div>
);

export default StatusCard;
